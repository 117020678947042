.c-pointer {
    cursor: pointer !important;
  }

  .user-img {
    top: 0.75rem;
    left: 3%;
  }

  .post-card,
  .comment,
  .reply-cmt-field {
    .user-img {
      background: #d2ddec;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .empty-img {
        color: #697891;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

.community-container .post-card .post-card-top .post-card-top-left{
    .user-img {
        height: 36px;
        width: 36px;
        .empty-img {
          font-size: 1rem;
        }
      }
}

.comment,
.reply-cmt-field {
  .user-img {
    min-width: 30px;
    height: 30px;
    .empty-img {
      font-size: 0.75rem;
    }
  }
}

  .student-ai-chat-avatar{
    .user-img{
        top: unset !important;
        left: unset !important;
        img {
        width: 2.625rem;
        height: 2.625rem;
        border-radius: 50%;
        }
      }
    
    .empty-img{
        width: 2.625rem;
        height: 2.625rem;
        background-color: #f5f9fd;
        border-radius: 50%;
        border: 1px solid #f5f9fd;
        color: #11294f;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
 }